import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useLivePoll } from 'src/hooks/store-hooks';
import { ILivePoll } from 'src/models/livepoll.interface';
import { resetStoreSelector } from 'src/stores/livePoll-store.selectors';
import { resetStoreSelector as resetLivePollDesignStoreSelector } from '../livepoll-design/store/LivePollDesignStore.selector';
import { useLivePollEditorStore } from 'src/stores/useLivePollEditor.store';
import { useLivePollDesignStore } from '../livepoll-design/store/useLivePollDesign.store';

export const LivePollEditorAndAnalytics = () => {
  const params = useParams<{ livePollId: string }>();
  const livePollId = +params.livePollId!;
  const resetLivePollEditorStore = useLivePollEditorStore(resetStoreSelector);
  const resetLivePollDesignStore = useLivePollDesignStore(
    resetLivePollDesignStoreSelector,
  );

  const { isLoading, isError, error, remove } = useLivePoll(livePollId, () => {
    return (data: ILivePoll) => {
      useLivePollEditorStore.getState().setLivePoll(data);
    };
  });

  // on un-mounting reset the LivePollEditorStore and remove the query key from cache.
  // This is required because sometimes the data is available from the cache
  // so in that case the onSuccess method is not called and we get the empty store
  useEffect(() => {
    return () => {
      resetLivePollDesignStore();
      resetLivePollEditorStore();
      remove();
    };
  }, [resetLivePollEditorStore, remove, resetLivePollDesignStore]);

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return <LpSpinner message="Loading LivePoll..." />;
  }

  return <Outlet></Outlet>;
};
