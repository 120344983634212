import { Header } from './components/header/header';
import styles from './app.module.css';
import { PrimaryNavbar } from './components/navbar/primary-navbar';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as AppUrls from './components/navbar/app.urls';
import ErrorBoundary from './wick-ui/error/ErrorBoundary';
import { LivePollListScreen } from './screens/livepoll-list/LivePollListScreen';
import { LivePollEditorNavbar } from './components/navbar/livepoll-editor-navbar';
import { OtherErrorBoundary } from './wick-ui/error/OtherErrorBoundary';
import { LpFooter } from '@livepolls/ui-components/src/components/footer/LpFooter';
import { CreateQuizFromTemplate } from './screens/livepoll-editor-from-template/create-quiz-from-template.screen';
import { TipType } from '@livepolls/ui-components/src/enums/tip-type.enum';
import { useTranslation } from 'react-i18next';
import { GoogleSlidesLivePollListScreen } from './screens/livepoll-list/GoogleSlidesLivePollListScreen';
import { lazy, useState } from 'react';
import { ISubmitFeedback } from './models/submit-feedback.interface';
import { useSubmitFeedBack } from './hooks/store-hooks';
import { WuButton } from '@npm-questionpro/wick-ui-lib';
import { LivePollEditorAndAnalytics } from './screens/livepoll-editor/livepoll-editor-analytics';

const DashboardScreen = lazy(
  () => import('./screens/report-dashboard/DashboardScreen'),
);
const PermanentRoomDashboardScreen = lazy(
  () => import('./screens/permanent-room/PermanentRoomDashboardScreen'),
);
const SessionListScreen = lazy(
  () => import('./screens/session-list/SessionListScreen'),
);
const LivePollFinishedScreen = lazy(
  () =>
    import(
      './screens/run-livepoll/components/livepoll-finished/LivePollFinishedScreen'
    ),
);
const RunLivePollSessionScreen = lazy(
  () => import('./screens/run-livepoll/run-livepoll-session-screen'),
);
const LivePollDesignScreen = lazy(
  () => import('./screens/livepoll-design/LivePollDesign.screen'),
);
const LivePollEditorScreen = lazy(
  () => import('./screens/livepoll-editor/livepoll-editor.screen'),
);
const PerformanceLogScreen = lazy(
  () => import('./screens/performace-logs/PerformanceLogScreen'),
);
const PresenterNotesWindow = lazy(
  () => import('./screens/run-livepoll/components/PresenterNotesWindow'),
);
const GoogleSlidesLoginSuccessScreen = lazy(
  () => import('./screens/google-slides/GoogleSlidesLoginSuccess.screen'),
);

const getTemplateUidFromParamsOrLocalStorageOrNull = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  let templateUid = params.get('livePollsTemplateUID');

  if (!templateUid) {
    templateUid = localStorage.getItem('templateUid');
  }
  return templateUid;
};

export const AuthenticatedApp = () => {
  const { t } = useTranslation();
  const submitFeedBack = useSubmitFeedBack();
  const [error, setError] = useState<boolean>(false);

  const locations = [
    t('austin'),
    t('media'),
    t('pune'),
    t('berlin'),
    t('london'),
    t('dubai'),
  ];

  const tips = [
    { type: TipType.LIFE_PRO_TIP, message: t('enterToAddOptions') },
    {
      type: TipType.LIFE_PRO_TIP,
      message: t('backspaceToDeleteAnOption'),
    },
    {
      type: TipType.YOU_SHOULD_KNOW,
      message: t('builtByThreeEngineers'),
    },
    {
      type: TipType.TODAY_I_LEARNED,
      message: t('qpInBusiness'),
    },
    {
      type: TipType.DO_YOU_KNOW,
      message: t('qpStarted'),
    },
    {
      type: TipType.TODAY_I_LEARNED,
      message: t('qpFirstCustomer'),
    },
  ];

  const submitResponse = async (response: ISubmitFeedback) => {
    try {
      await submitFeedBack.mutateAsync(response);
    } catch (err) {
      setError(true);
    }
  };

  const templateUid = getTemplateUidFromParamsOrLocalStorageOrNull();

  return (
    <div className={styles.appContainer}>
      <div className={styles.navbarContainer}>
        <Header />
        <PrimaryNavbar />
        <LivePollEditorNavbar />
      </div>

      <div className={styles.mainContainer}>
        {error && (
          <div>
            Oops an error occurred ({t('errorMsgSubmitFeedBack')}
            ).
            <WuButton onClick={() => window.location.reload()}>
              Try again
            </WuButton>
          </div>
        )}

        {!error && (
          <Routes>
            <Route
              path={AppUrls.URLS.EDIT_LIVE_POLL_URL}
              element={
                <OtherErrorBoundary>
                  <LivePollEditorAndAnalytics />
                </OtherErrorBoundary>
              }
            >
              <Route
                path={AppUrls.LIVEPOLL_EDITOR_SCREEN_RELATIVE_URLS.DASHBOARD}
                element={
                  <OtherErrorBoundary>
                    <DashboardScreen />
                  </OtherErrorBoundary>
                }
              />
              <Route
                path={AppUrls.LIVEPOLL_EDITOR_SCREEN_RELATIVE_URLS.SESSIONS}
                element={
                  <OtherErrorBoundary>
                    <SessionListScreen />
                  </OtherErrorBoundary>
                }
              />
              <Route
                path={AppUrls.LIVEPOLL_EDITOR_SCREEN_RELATIVE_URLS.DESIGN}
                element={
                  <OtherErrorBoundary>
                    <LivePollDesignScreen />
                  </OtherErrorBoundary>
                }
              />
              <Route
                path={
                  AppUrls.LIVEPOLL_EDITOR_SCREEN_RELATIVE_URLS
                    .GOOGLE_SLIDES_EDITOR
                }
                element={
                  <OtherErrorBoundary>
                    <LivePollEditorScreen />
                  </OtherErrorBoundary>
                }
              />
              <Route
                path={
                  AppUrls.LIVEPOLL_EDITOR_SCREEN_RELATIVE_URLS
                    .GOOGLE_SLIDES_DESIGN
                }
                element={
                  <OtherErrorBoundary>
                    <LivePollDesignScreen />
                  </OtherErrorBoundary>
                }
              />
              <Route
                index={true}
                element={
                  <OtherErrorBoundary>
                    <LivePollEditorScreen />
                  </OtherErrorBoundary>
                }
              />
            </Route>
            <Route
              path={AppUrls.URLS.PERMANENT_ROOM_SESSION_DASHBOARD}
              element={
                <OtherErrorBoundary>
                  <PermanentRoomDashboardScreen />
                </OtherErrorBoundary>
              }
            />

            <Route
              path={AppUrls.URLS.LIVE_POLL_EDITOR_FROM_TEMPLATE}
              element={
                <OtherErrorBoundary>
                  <CreateQuizFromTemplate />
                </OtherErrorBoundary>
              }
            />
            <Route
              path={AppUrls.URLS.FINISH_TAKE_LIVE_POLL_URL}
              element={
                <OtherErrorBoundary>
                  <LivePollFinishedScreen />
                </OtherErrorBoundary>
              }
            />
            <Route
              path={AppUrls.URLS.TAKE_LIVE_POLL_URL}
              element={
                <ErrorBoundary componentName="RunLivePollSessionScreen">
                  <RunLivePollSessionScreen />
                </ErrorBoundary>
              }
            />
            <Route
              path={AppUrls.URLS.TAKE_LIVE_POLL_FOR_SLIDES_URL}
              element={
                <ErrorBoundary componentName="RunLivePollSessionScreen">
                  <RunLivePollSessionScreen />
                </ErrorBoundary>
              }
            />
            <Route
              caseSensitive={true}
              path={AppUrls.URLS.LIVE_POLL_LIST_URL}
              element={
                <OtherErrorBoundary>
                  <LivePollListScreen />
                </OtherErrorBoundary>
              }
            />

            <Route
              caseSensitive={true}
              path={AppUrls.URLS.LIVE_POLL_LIST_FOR_SLIDES_URL}
              element={
                <OtherErrorBoundary>
                  <GoogleSlidesLivePollListScreen />
                </OtherErrorBoundary>
              }
            />

            <Route
              path={AppUrls.URLS.ADMIN_PERFORMANCE_LOGS_URL}
              element={
                <OtherErrorBoundary>
                  <PerformanceLogScreen />
                </OtherErrorBoundary>
              }
            />

            <Route
              path={AppUrls.URLS.PRESENTER_VIEW_WINDOW_URL}
              element={
                <OtherErrorBoundary>
                  <PresenterNotesWindow />
                </OtherErrorBoundary>
              }
            />
            <Route
              path={AppUrls.URLS.GOOGLE_SLIDES_LOGIN_SUCCESS}
              element={
                <OtherErrorBoundary>
                  <GoogleSlidesLoginSuccessScreen />
                </OtherErrorBoundary>
              }
            />

            {templateUid && (
              <Route
                path="/"
                element={
                  <Navigate
                    to={AppUrls.URLS.LIVE_POLL_EDITOR_FROM_TEMPLATE}
                    state={templateUid}
                    replace
                  />
                }
              ></Route>
            )}

            <Route
              path="*"
              element={
                <Navigate to={`/${AppUrls.URLS.LIVE_POLL_LIST_URL}`} replace />
              }
            />
          </Routes>
        )}
      </div>

      <LpFooter
        text={{
          locations,
          tips,
          with: t('with'),
          from: t('from'),
          poweredBy: t('poweredBy'),
          rateUs: t('rateUs'),
          cancel: t('cancel'),
          submit: t('submit'),
          feedback: t('feedback'),
          rating: t('rating'),
        }}
        submitFeedback={submitResponse}
      />
    </div>
  );
};
