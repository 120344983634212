import { NavItemDetails } from 'src/models/nav-item-details.interface';
import { matchUrls } from './url.util';

export const isAnyRouteActive = (
  currentLocation: string,
  navItems: NavItemDetails[],
): boolean => {
  return navItems.find(n => isRouteActive(currentLocation, n)) != null;
};

const isRouteActive = (
  currentLocation: string,
  navItem: NavItemDetails,
): boolean => {
  const isParentRouteActive = matchUrls(currentLocation, navItem.url);

  // If parent route does not match, check if any child route is matching
  if (
    !isParentRouteActive &&
    typeof navItem.childRoutes !== 'undefined' &&
    navItem.childRoutes.length > 0
  ) {
    return (
      navItem.childRoutes.filter(childUrl =>
        matchUrls(currentLocation, childUrl),
      ).length > 0
    );
  }

  return isParentRouteActive;
};
