import { ILivePollDesignStore } from './useLivePollDesign.store';

export const themesSelector = (state: ILivePollDesignStore) => state.themes;
export const themeNameSelector = (state: ILivePollDesignStore) => state.name;
export const logoSelector = (state: ILivePollDesignStore) => state.logo;
export const coverImageSelector = (state: ILivePollDesignStore) =>
  state.coverImage;

export const fontFamilySelector = (state: ILivePollDesignStore) =>
  state.fontFamily;
export const coverColorSelector = (state: ILivePollDesignStore) =>
  state.coverColor;
export const mainColorSelector = (state: ILivePollDesignStore) =>
  state.mainColor;
export const buttonColorSelector = (state: ILivePollDesignStore) =>
  state.buttonColor;
export const backgroundOverlaySelector = (state: ILivePollDesignStore) =>
  state.bgOverlay;
export const titleColorSelector = (state: ILivePollDesignStore) =>
  state.titleColor;
export const answerColorSelector = (state: ILivePollDesignStore) =>
  state.answerColor;
export const sessionNameColorSelector = (state: ILivePollDesignStore) =>
  state.sessionNameColor;
export const themeIdSelector = (state: ILivePollDesignStore) => state.id;
export const isPropertyChangedSelector = (state: ILivePollDesignStore) =>
  state.isPropertyChanged;
export const setLogoSelector = (state: ILivePollDesignStore) => state.setLogo;
export const setCoverImageSelector = (state: ILivePollDesignStore) =>
  state.setCoverImage;

export const setFontFamilySelector = (state: ILivePollDesignStore) =>
  state.setFontFamily;
export const setCoverColorSelector = (state: ILivePollDesignStore) =>
  state.setCoverColor;
export const setMainColorSelector = (state: ILivePollDesignStore) =>
  state.setMainColor;
export const setButtonColorSelector = (state: ILivePollDesignStore) =>
  state.setButtonColor;
export const setBackgroundOverlaySelector = (state: ILivePollDesignStore) =>
  state.setBackgroundOverlay;
export const setTitleColorSelector = (state: ILivePollDesignStore) =>
  state.setTitleColor;
export const setAnswerColorSelector = (state: ILivePollDesignStore) =>
  state.setAnswerColor;
export const setSessionNameColorSelector = (state: ILivePollDesignStore) =>
  state.setSessionNameColor;
export const resetStoreSelector = (state: ILivePollDesignStore) =>
  state.resetStore;
export const addNewThemeMutationSelector = (state: ILivePollDesignStore) =>
  state.addNewThemeMutation;
export const deleteThemesMutationSelector = (state: ILivePollDesignStore) =>
  state.deleteThemesMutation;
export const updateThemeMutationSelector = (state: ILivePollDesignStore) =>
  state.updateThemeMutation;
export const loadingSelector = (state: ILivePollDesignStore) => state.loading;
export const errorSelector = (state: ILivePollDesignStore) => state.error;
export const setCurrentThemePropsSelector = (state: ILivePollDesignStore) =>
  state.setCurrentThemeProps;
export const themeTypeSelector = (state: ILivePollDesignStore) => state.type;
export const updateIsPropertyChangedSelector = (state: ILivePollDesignStore) =>
  state.updateIsPropertyChanged;
export const updateLivePollCustomizedThemeSelector = (
  state: ILivePollDesignStore,
) => state.updateLivePollCustomizedTheme;
